enum GeneralRoutes {
  auth = 'auth',
  admin = 'admin',
  dashboard = 'dashboard',
  mobile = 'mobile',
  public = 'public',
}

enum AuthRoutes {
  login = 'login',
  reset = 'reset/:token/:email',
}

/**
 * Entities that are accessed via the manage dashboard
 */
export enum ManagedRoutes {
  dashboard = 'dashboard',
  planning = 'planning',
  productGroup = 'product-group',
  product = 'product',
  observation = 'observation',
  productGrading = 'product-grading',
  block = 'block',
  package = 'package',
  farm = 'farm',
  organisation = 'organisation',
  moreActions = 'more-actions',
  upload = 'upload',
  farmProductionBudget = 'farm-production-budget',
  translation = 'translation',
  reference = 'reference',
  farmRole = 'farm-role',
  address = 'address',
  contact = 'contact',
  deliveryCondition = 'delivery-condition',
  productPacking = 'product-packing',
  productBaseRate = 'product-base-rate',
  salesChannel = 'sales-channel',
  salesContract = 'sales-contract',
  received = 'received',
  receiving = 'receiving',
  awaiting = 'awaiting',
  rejectAmount = 'reject-amount',
  rejectReason = 'reject-reason',
  region = 'region',
  deliveryLag = 'delivery-lag',
  harvestPlanning = 'harvest-planning',
  priceRate = 'price-rate',
  deliveryOrder = 'delivery-order',
  batch = 'batch',
  location = 'location',
  journals = 'journals',
  floridayErrors = 'floriday-errors',
  gradingStation = 'grading-station',
  grading = 'grading',
  user = 'user',
  transport = 'transport',
  floricodeUpload = 'floricode-upload',
  auctionSales = 'auction-sales',
  manualAuctionOrder = 'manual-auction-order',
  auctionStrategy = 'auction-strategy',
  baseData = 'base-data',
  villagePlanning = 'village-planning',
  tradeItem = 'trade-item',
  recordGradedHarvest = 'record-graded-harvest',
  recordRejectedHarvest = 'record-rejected-harvest',
  gradedStock = 'graded-stock',
  boxedStock = 'boxed-stock',
  rejectedHarvest = 'rejected-harvest',
  setting = 'setting',
  role = 'role',
  shipment = 'shipment',
  harvestDeliveryNote = 'harvest-delivery-note',
  builder = 'builder',
  powerBI = 'power-bi',
  reports = 'reports',
  clients = 'clients',
  configuration = 'configuration',
  batchPlanning = 'batch-planning',
  productFile = 'product-file',
  forecastAllocation = 'forecast-allocation',
  holiday = 'holiday',
}

/**
 * Entities that are accessed via the sidebar menu
 */
export enum SidebarRoutes {
  marketDemand = 'market-demand',
  order = 'sales',
  production = 'production',
  harvest = 'harvest',
  insight = 'insight',
  allocation = 'allocation',
  fulfillment = 'fulfillment',
  finance = 'finance',
  logistics = 'logistics',
  gradedStock = 'graded-stock',
  shipment = 'shipment',
  manage = 'manage',
  packageManagement = 'package-management',
}

enum UserRoutes {
  profile = 'profile',
  settings = 'settings',
  changeFarm = 'change-farm',
}

export enum CommonRoutes {
  detail = 'detail',
  manual = 'manual',
  overview = 'overview',
  list = 'list',
  form = 'form',
  release = 'release',
}

export enum FormRoutes {
  edit = 'edit',
  add = 'add',
  copy = 'copy',
  delete = 'delete',
  confirm = 'confirm',
}

enum RouteParams {
  editId = 'edit/:id',
}

enum MobileRoutes {
  growing = 'growing',
  harvesting = 'harvesting',
  comment = 'comment',
  parameters = 'parameters',
  scanner = 'scanner',
  rejectedStock = 'rejected-stock',
  sorting = 'sorting',
  collection = 'collection',
  gradingSession = 'grading-session',
  moveHarvest = 'harvest-move',
  scanHarvest = 'scan-harvest',
  scanStation = 'scan-station',
  syncErrors = 'sync-errors',
  startShipment = 'start-shipment',
  recordEmpty = 'record-empty',
  confirmShipment = 'confirm-shipment',
  availableStock = 'available-stock',
  orderScanner = 'order-scanner',
  boxScanner = 'box-scanner',
  information = 'information',
  stock = 'stock',
  recordRejections = 'record-rejections',
  product = 'product',
  confirmLots = 'confirm-lots',
}

enum CustomRoutes {
  table = 'table',
  chart = 'chart',
}

enum PublicRoutes {
  shipment = 'shipment',
  temperature = 'temperature',
}

enum ReportRoutes {
  teamPerformance = 'team-performance',
  demandSupply = 'demand-supply',
  price = 'price',
  sales = 'sales',
  priceElasticity = 'price-elasticity',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...GeneralRoutes,
  ...AuthRoutes,
  ...ManagedRoutes,
  ...SidebarRoutes,
  ...UserRoutes,
  ...RouteParams,
  ...MobileRoutes,
  ...CommonRoutes,
  ...FormRoutes,
  ...CustomRoutes,
  ...PublicRoutes,
  ...ReportRoutes,
};
