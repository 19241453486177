export class RouterUtil {
  public static backUrl(backUrl: string): object {
    return { queryParams: { returnUrl: backUrl } };
  }

  public static keepUrlTree(url: string): [string[], object?] {
    if (url && url.includes('?returnUrl=')) {
      const dividedUrl = url.split('?returnUrl=');

      return [[dividedUrl[0]], RouterUtil.backUrl(RouterUtil.revertUrlChanges(dividedUrl[1]))];
    }

    return [[url]];
  }

  public static revertUrlChanges(url: string): string {
    if (url) {
      return url
        .split('%3D')
        .join('')
        .split('%2F')
        .join('/')
        .split('%252F')
        .join('/')
        .split('%253F')
        .join('?')
        .split('%253D')
        .join('');
    }
  }
}
