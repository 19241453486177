import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class TitleHelper {
  public static defaultDivider = ' | ';

  public static concatTitles(titles: (string | Observable<string>)[], divider?: string): string | Observable<string> {
    const titles$ = titles.map((title) => {
      return isObservable(title) ? title : of(title);
    });

    return combineLatest(titles$).pipe(
      map((values: string[]) => {
        return TitleHelper.generateTitle(values, divider);
      }),
    );
  }

  private static generateTitle(titles: string[], divider: string): string {
    return titles.join(divider ? divider : TitleHelper.defaultDivider);
  }
}
