import { Injectable, TemplateRef } from '@angular/core';
import { ActionMenu } from '@core/models/actionMenu.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';
import { Router } from '@angular/router';
import { TitleHelper } from '@core/utils/title.helper';
import { TranslateService } from '@ngx-translate/core';
import { EntityConfig } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { ManagedRoutes } from '@core/enums/routes.enum';
import { RouterUtil } from '@core/utils/router-util';

export enum ButtonType {
  DEFAULT = 'default',
  SUBMIT = 'submit',
  SAVE = 'save',
  EDIT = 'update',
  DELETE = 'delete',
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
  ADD = 'create',
  DISCARD = 'discard',
}

export interface ActionButton {
  type: ButtonType;
  label?: Observable<string> | string;
  styleClass?: string;
  icon?: string | Observable<string>;
  route?: string | string[];
  callback?: () => void;
  permissions?: string[];
  testAttrName?: string;
  isHidden?: boolean | Observable<boolean>;
  debounceTime?: number;
}

export enum LayoutPosition {
  TITLE_RIGHT = 'title_right',
  TITLE_CENTER = 'title_center',
  TITLE_LEFT = 'title_left',
}

// TODO: Add further improvements to make depending on size
export interface LayoutCustomComponent {
  position: LayoutPosition;
  templateRef: TemplateRef<any>;
}

export interface LayoutConfig {
  url: string; // Identifier url
  backUrl?: string | string[]; // Route to previous url
  backUrlTab?: string; // specific tab of previous url
  entity?: Entity | DisplayEntity; // Entity used for generating default buttonTypes, testing, etc.
  title?: string | Observable<string>; // If no title, show logo
  subtitle?: string | Observable<string>;
  tabItems?: any[]; // Tabs to display on mobile
  actionButtons?: ActionButton[]; // Buttons that interact with page/entity
  actionMenu?: ActionMenu[];
  customComponents?: LayoutCustomComponent[];
}

@Injectable({
  providedIn: 'root',
})
export class LayoutConfigService extends DestroyBase {
  public layoutConfig$: BehaviorSubject<LayoutConfig>;
  public tabIndexChanged$: BehaviorSubject<number> = new BehaviorSubject(0);
  public activeTabIndex = 0;

  private configs: LayoutConfig[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
  ) {
    super();
    this.layoutConfig$ = new BehaviorSubject<LayoutConfig>({
      url: this.router.url,
    });
  }

  public addConfig(config: LayoutConfig): void {
    const index = this.configs.findIndex((item) => {
      return item.url === config.url;
    });

    if (index === -1) {
      this.configs = [...this.configs, config];
    } else {
      this.configs[index] = config;
    }

    this.layoutConfig$.next(config);
  }

  public toggleActionDisable(menuItemIndex: number, newValue: boolean): void {
    const activeConfig = this.layoutConfig$.getValue();

    if (
      !activeConfig ||
      !activeConfig.hasOwnProperty('actionMenu') ||
      !activeConfig.actionMenu.length ||
      !(activeConfig.actionMenu.length >= menuItemIndex)
    ) {
      return;
    }

    activeConfig.actionMenu[menuItemIndex].disabled = newValue;

    this.layoutConfig$.next(activeConfig);
  }

  public getConfig(url: string): LayoutConfig {
    return this.configs.find((config) => {
      return config.url === url;
    });
  }

  public setActiveTab($event: any): void {
    this.activeTabIndex = $event.index;

    this.tabIndexChanged$.next(this.activeTabIndex);
  }

  public generateSubtitle(additionalParts: (string | Observable<string>)[]): string | Observable<string> {
    return TitleHelper.concatTitles(additionalParts);
  }

  public generateDefaultTitleTranslationKeys(entityConfig: EntityConfig): Observable<string>[] {
    return (Object.values(ManagedRoutes) as string[]).includes(entityConfig.name)
      ? [
          this.translateService.stream('dxp.manage.overview.title'),
          this.translateService.stream(`dxp.${entityConfig.name}.title`),
        ]
      : [this.translateService.stream(`dxp.sidebar.${entityConfig.name}`)];
  }

  public generateDefaultConfig(entityConfig: EntityConfig): Observable<LayoutConfig> {
    return of({
      title: TitleHelper.concatTitles(this.generateDefaultTitleTranslationKeys(entityConfig), ' - '),
      entity: entityConfig.name,
      url: this.router.url,
    });
  }

  public doAction(button: ActionButton): void {
    if (button.route) {
      if (Array.isArray(button.route)) {
        this.router.navigate(button.route);
      } else if (typeof button.route === 'string') {
        this.router.navigate(...RouterUtil.keepUrlTree(button.route));
      }
    } else if (button.callback) {
      button.callback();
    }
  }
}
